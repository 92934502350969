
import Vue from "vue";
import { mapGetters } from "vuex";
import MentoringProgramFormContainer from "@/components/MentoringPlan/MentoringProgramFormContainer.vue";

export default Vue.extend({
  name: "EditMentoringProgramPage",
  components: {
    MentoringProgramFormContainer
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile"
    })
  }
});
